import React, {useState, useEffect} from 'react'
import Layout from '../../components/layout'
import HubspotForm from "react-hubspot-form"
// import { Typewriter } from 'react-simple-typewriter'
import PerksDiscountCarousel from '../../components/Carousels/PerksDiscountCarousel'
import Helmet from "react-helmet"
import WithProductPageForm from '../../components/ProductPageForm'
import { useLanguages } from '../../hooks/useLanguages'

const Vcperks = ({ location }) => {

    const [image, setImage] = useState('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/perks-exclusive.webp')
    const [dashboardImage, setdashboardImage] = useState('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/perks-cashback.webp')
    const [perksDashboard, setperksDashboard] = useState('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/Perks-dashboard-2-scaled.webp')
    const [currLanguage] = useLanguages()

    useEffect(() => {
      if(typeof window !== undefined) {
        if(window.innerWidth < 769){
            setImage('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/perks-exclusive-Mobile.webp')
            setdashboardImage('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/mobile-perks-cashback.webp')
            setperksDashboard('https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/Perks-dashboard-2-scaled.webp')
        }
      }
    }, [])

  return (
    <Layout location={location} pageLanguage={currLanguage}>
        <Helmet>
            <title>Employee Discount, Corporate Perks & Employee Benefits | Vantage Perks</title>

            <meta name="description" content= "Employee discount and corporate perks for employees - Special discount and offers on top brands with Vantage Circle Employee Discount Program." />
            <meta property="twitter:title" content="Employee Discount, Corporate Perks & Employee Benefits | Vantage Perks" />
            <meta name= "robots" content= "index" />
                    
            <meta property="twitter:description" content="Employee discount and corporate perks for employees - Special discount and offers on top brands with Vantage Circle Employee Discount Program." />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/products/employee-discount/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <meta property="og:title" content="Employee Discount, Corporate Perks & Employee Benefits | Vantage Perks" />

            <meta property="og:description" content="Employee discount and corporate perks for employees - Special discount and offers on top brands with Vantage Circle Employee Discount Program." />
            <link rel="canonical" href="https://www.vantagecircle.com/products/employee-discount/"></link>
        </Helmet>

        <section id='watchVideoClass' class="w-11/12 2xl:max-w-7xl mx-auto rounded-xl flex place-content-center relative bg-purple-80 overflow-x-hidden pt-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
                <div className="w-full grid lg:grid-cols-2 justify-center items-start z-10 lg:px-10 relative">
                    <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 xl:pt-20 xl:pb-20">
                            <div className="my-2 flex justify-center items-center">
                                <h2 className="pl-1 text-center lg:text-left heroTagStyle">CORPORATE EMPLOYEE DISCOUNT</h2>
                            </div>
                            <h1 class="text-newOrange lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left relative">Supercharge</h1>
                            <h1 className = "lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left text-indigo-100">employee benefits.</h1>
                            <p className="my-8 text-indigo-100 text-center lg:text-left w-full text-black" style= {{ lineHeight: '2rem' }}>Offer your employees exclusive corporate offers, discounts and cashback bonanza on 1000+ global brands with <span className='font-bold'>Vantage Perks.</span></p>
                            <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-10/12 2xl:w-full cursor-pointer my-8 z-10">
                                <a className="vc-new-orange-btn-rounded-full mx-auto lg:mx-0 text-white lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="#form">Book a product demo</a>
                                <button className="youtube-video-btn vc-new-indigo-border-btn-rounded-full text-indigo-500 lato rounded-full mt-3 xl:mt-0 xl:ml-2 modal-open modal-toggle">Watch video</button>
                            </div>      
                    </div>
                    <div className="lg:absolute md:-bottom-64 md:scale-90 lg:scale-75 lg:transform xl:scale-100 md:right-5 lg:-bottom-12 lg:-right-10 xl:-bottom-1 xl:right-5 2xl:right-3 flex justify-center items-center lg:h-full mt-6 pt-4 xl:mt-0 lg:ml-3">
                        <picture>
                            <source className='z-10 relative' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/Perks-hero.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="500" height="1236" />
                            <img className='z-10 relative' src="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2023/11/Perks-hero.webp" alt="Vantage Perks" width="500" height="572" />
                        </picture>
                    </div>  
                </div>
        </section>
        <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
            <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                    <svg class="fill-current text-white buttonClose" width="30" height="30" viewBox="0 0 18 18">
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                    </svg>
                </button>
                <div class="modal-content text-left">
                    <div class="aspect-w-16 aspect-h-9">                                
                        <iframe class="video"
                            width="560"
                            height="315"
                            src=""
                            data-src="https://www.youtube.com/embed/XlPda4zuy2k"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            title="Vantage Circle"
                            ></iframe>
                    </div>
                </div>   
            </div>
        </div>

        <section className='w-full py-10 xl:py-16' id="workforce">
                <div className='w-11/12 lg:max-w-7xl mx-auto'>
                    <h2 className='homepage-heading text-center'><span className= "text-orange">Improve</span> your employer's brand. <span className='text-orange'>Enhance</span> your EVP.<br/><span className='text-orange'> Attract</span> more talent.</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-content-center gap-5 mt-6 md:mt-10 xl:mt-16 2xl:mt-20'>
                        <div className='p-4 md:p-4 flex justify-start items-start mt-7 lg:mt-0'>
                            <div className='w-1/3'>
                                <picture>
                                    <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/03/Perks-stat01.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="400" height="188" />
                                    <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/03/Perks-stat01.webp" alt="Vantage Rewards" width="400" height="87" />
                                </picture>
                            </div>
                            <div className='w-2/3'><p className='text-gray-500 pl-2 p-0 m-0'>employees would prefer additional benefits or perks over a pay raise.</p></div>
                        </div>
                        <div className='p-4 m-0 md:p-4 flex justify-start items-start'>
                            <div className='w-1/3'>
                                <picture>
                                    <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/03/Perks-stat02.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="400" height="188" />
                                    <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/03/Perks-stat02.webp" alt="Vantage Rewards" width="400" height="87" />
                                </picture>
                            </div>
                            <div className='w-2/3'><p className='text-gray-500 pl-2 p-0 m-0'>millennials said that a good benefits package is a crucial factor when choosing an employer.</p></div>
                        </div>
                        <div className='p-4 md:p-4 flex justify-start items-start'>
                            <div className='w-1/3'>
                                <picture>
                                    <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/03/Perks-stat03.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="400" height="188" />
                                    <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/03/Perks-stat03.webp" alt="Vantage Rewards" width="400" height="87" />
                                </picture>
                            </div>
                            <div className='w-2/3'><p className='text-gray-500 pl-2 p-0 m-0'>people said that benefits and perks are major factors in considering whether to accept a job offer.</p></div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='w-full 2xl:max-w-7xl mx-auto py-10 xl:py-16 bg-gray-newGrayDark'>
                <div className='w-11/12 xl:max-w-7xl mx-auto rounded-xl pb-10 xl:pb-6 px-4'>
                    <p className='text-center p-0 m-0'>EXCLUSIVE DISCOUNTS</p>
                    <h2 class="homepage-heading py-6 text-center max-w-5xl mx-auto">Exclusive corporate discounts- <br/>too good to dismiss!</h2>
                    <div className=''>
                        <ul className='px-3 pt-6 xl:py-10 grid md:grid-cols-2 xl:grid-cols-4 gap-16 xl:gap-10 w-full mx-auto justify-center'>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-globalbonanza.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="44" height="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-globalbonanza.webp" alt="Vantage Rewards" width="44" height="44" />
                                </picture>
                                <h3 className='pb-2'>Global brand bonanza</h3>
                                <p className="text-base m-0">Compare and find best deals from top brands.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-location.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="44" height="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_44/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-location.png" alt="Vantage Rewards" width="44" height="44" />
                                </picture>
                                <h3 className='pb-2'>70+ Locations</h3>
                                <p className="text-base m-0">We've got you covered wherever you operate</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-getmore.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="44" height="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-getmore.webp" alt="Vantage Rewards" width="44" height="44" />
                                </picture>
                                <h3 className='pb-2'>Get more for less</h3>
                                <p className="text-base m-0">Tangible savings across multiple categories.</p>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white shadow-xl px-6 py-10 relative'>
                                <picture className='absolute left-5 -top-4 z-10'>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_92/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-discount.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="44" height="44" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload//w_44/q_auto/gatsbycms/uploads/2023/08/perks-exclusive-discount.webp" alt="Vantage Rewards" width="44" height="44" />
                                </picture>
                                <h3 className='pb-2'>Exclusive offers for your employees:</h3>
                                <p className="text-base m-0">Negotiated just for them.</p>
                            </div>
                        </ul>
                        <div className='pt-7 mt-4 px-2'>
                            
                            <picture>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1232/q_auto/gatsbycms/uploads/2024/03/perks01.webp" media= "(min-width: 640px)" type="image/webp" alt="exclusive-discounts" width="1232" height="446" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_346/q_auto/gatsbycms/uploads/2024/03/perks01-mobile.webp" alt="exclusive-discounts" width="346" height="752" />
                            </picture>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-10 xl:py-20 w-full px-3'>
                    <h2 className='homepage-heading mx-auto text-center'>Discount across various categories</h2>
                    
                    <div className ="w-full md:max-w-7xl mx-auto my-10 xl:my-16 flex place-content-center">
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1280/q_auto/gatsbycms/uploads/2024/03/category-static.webp" media= "(min-width: 640px)" type="image/webp" alt="exclusive-discounts" width="1232" height="446" />
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_380/q_auto/gatsbycms/uploads/2024/03/category-static-mobile.webp" alt="exclusive-discounts" width="346" height="752" />
                        </picture>
                    </div>
                    <div class="w-5/6 md:w-1/3 xl:w-1/5 2xl:w-1/6 mx-auto"><a href="#PerksVC" class="vc-new-orange-btn-rounded-full text-white lato mx-auto rounded-full mt-10 lg:mt-0">Book a product demo</a></div>

            </section>

            <section className='w-full 2xl:max-w-7xl py-10 xl:py-16 bg-gray-newGrayDark mx-auto'>
                <div className='w-11/12 xl:max-w-7xl 2xl:w-full mx-auto rounded-xl'>
                    <p className='text-center p-0 m-0'>CASHBACK OFFERS</p>
                    <h2 class="homepage-heading py-3 text-center max-w-5xl mx-auto">Make every purchase twice as rewarding with<span class="text-orange"> cashback offers.</span></h2>
                    <div className=''>
                        <ul className='pl-6 pt-6 xl:pt-10 grayTick md:grid grid-cols-2 xl:grid-cols-4 gap-10 md:gap-y-5 md:gap-x-10 xl:gap-10 w-11/12 mx-auto justify-center'>
                            <li className='flex'><p className="text-base">Enjoy cashback offers across global brands</p></li>
                            <li className='flex'><p className="text-base">Earn cashback points on top of discounts offered</p></li>
                            <li className='flex'><p className="text-base">Purchase gift cards with cashback points at zero markup</p></li>
                            <li className='flex'><p className="text-base">Shop using Perks and get a chance to earn bonus points</p></li>
                        </ul>
                        <div className='pt-6 px-4'>
                  
                            
                            <picture>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1248/q_auto/gatsbycms/uploads/2023/08/perks-cashback.webp" media= "(min-width: 640px)" type="image/webp" alt="exclusive-discounts" width="1248" height="660" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_362/q_auto/gatsbycms/uploads/2023/09/mobile-perks-cashback.webp" alt="cashback-offers" width="362" height="416" />
                            </picture>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='w-full 2xl:max-w-7xl mx-auto flex justify-end overflow-hidden py-10 lg:pt-20 lg:pb-0 relative' style={{ backgroundColor: '#fe8934' }}>
                <div className='w-80 h-80 absolute -top-28 left-1/2 border bg-white rounded-full z-0' style= {{ borderWidth: '28px', background:'transparent', borderColor: '#e17222' }}></div>
                <div className='lg:flex xl:flex lg:justify-end items-end'>
                    <div className='grid place-content-center self-center text-center lg:text-left w-full lg:w-1/3 pb-10 lg:mb-10 mx-auto lg:mx-0 lg:pl-12 xl:pl-0 2xl:pl-5'>
                        <h2 className='homepage-heading text-white z-10'>Become a brand partner</h2>
                        <p className='mt-6 mx-auto lg:mx-0 w-5/6 lg:w-5/6 2xl:w-full text-white z-10'>Join our brand journey in providing employees around the world a PERK-ful shopping experience.</p>
                        <div className='mx-auto lg:mx-0 lg:w-5/6 xl:w-4/6 my-3'><a className= "vc-new-white-border-btn-rounded-full text-white" href = "/strategic-partnership/">Partner with us</a></div>
                    </div>
                    <picture className='lg:w-1/3 flex justify-center self-start z-10'>
                        <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/08/perks-partner-brands-web.webp" media= "(min-width: 640px)" type="image/webp" alt="dashboard" width="300px" height= "469" />
                        <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/08/perks-partner-brands-web.png" alt="dashboard" width="300px" height="469" />
                    </picture>
                    <picture className='1/3 hidden lg:block'>
                        <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/e_trim/gatsbycms/uploads/2023/08/perks-partner-character-web.webp" media= "(min-width: 640px)" type="image/webp" alt="dashboard" width="400px" height="475" />
                        <img loading="lazy" src="" alt="dashboard" width="400px" />
                    </picture>
                </div>
            </section>

            <section className='w-full flex place-content-center my-10 xl:my-32 px-5'>
                <div className='max-w-7xl rounded-xl mx-0 lg:mx-5 p-3 pb-0 md:px-5 lg:pl-10 lg:pr-20 xl:pr-32 2xl:pr-20 grid place-content-center lg:grid-cols-2 bgPerksOrg overflow-hidden relative'>
                    <div className='bg-indigo-100 p-6 lg:p-10 rounded-xl grid place-content-center my-11'>
                        <p className='text-center text-purple-100 md:text-left p-0 m-0'>CLASSFIELDS</p>
                        <h2 className='homepage-heading text-center lg:text-left text-purple-100 mt-3'>Your organization's own <span className='text-orange'>Marketplace</span></h2>
                        <div className='mt-5'>
                            <div className='my-6 xl:my-5 flex items-start'>
                                <picture className='pt- lg:pt-0 w-2/12 lg:w-1/12 transform'>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_40/q_auto/e_trim/gatsbycms/uploads/2023/09/Perks-post.webp" type="image/webp" alt="dashboard" width="40px" height="40px" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40/q_auto/e_trim/gatsbycms/uploads/2023/09/Perks-post.png" alt="dashboard" width="40px" height="40px" />
                                </picture>
                                <p className=' w-11/12 pl-5 m-0 p-0 lg:pt-1 lg:pt-0 xl:pt-1 text-purple-100'>Post ads conveniently.</p>
                            </div>
                            <div className='my-6 xl:my-5 flex items-start'>
                                <picture className='pt- lg:pt-0 w-2/12 lg:w-1/12 transform'>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_40/q_auto/e_trim/gatsbycms/uploads/2023/09/Perks-buy.webp" type="image/webp" alt="dashboard" width="40px" height="40px" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40/q_auto/e_trim/gatsbycms/uploads/2023/09/Perks-buy.webp" alt="dashboard" width="40px" height="40px" />
                                </picture>
                                <p className=' w-11/12 pl-5 m-0 p-0 lg:pt-1 lg:pt-0 xl:pt-1 text-purple-100'>Buy and sell internally with ease.</p>
                            </div>
                            <div className='my-6 xl:my-5 flex items-start'>
                                <picture className='pt- lg:pt-0 w-2/12 lg:w-1/12 transform'>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_40/q_auto/e_trim/gatsbycms/uploads/2023/09/Perks-safereliable.webp" type="image/webp" alt="dashboard" width="40px" height="40px" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_40/q_auto/e_trim/gatsbycms/uploads/2023/09/perks-exclusive-location.png" alt="dashboard" width="40px" height="40px" />
                                </picture>
                                <p className=' w-11/12 pl-5 m-0 p-0 lg:pt-1 lg:pt-0 xl:pt-1 text-purple-100'>A safe and reliable marketplace at your fingertips.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-end justify-center lg:justify-end'>
                        <picture className=''>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/e_trim/gatsbycms/uploads/2023/08/perks-classifieds-phone.webp" media= "(min-width: 640px)" type="image/webp" alt="dashboard" width="350px" height="494" />
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/e_trim/gatsbycms/uploads/2023/08/perks-classifieds-phone.png" alt="dashboard" width="350px" height="439" />
                        </picture>
                    </div>
                </div>
            </section>

            <section className='w-full 2xl:max-w-7xl py-10 xl:py-16 bg-gray-newGrayDark mx-auto'>
                <div className='w-11/12 xl:max-w-7xl 2xl:w-full mx-auto rounded-xl'>
                    <p className='text-center p-0 m-0'>DASHBOARD</p>
                    <h2 class="homepage-heading py-3 text-center max-w-3xl mx-auto"><span class="text-orange">Effortlessly</span> access and manage employee perks.</h2>
                    <div className=''>
                        <div className='md:grid md:grid-cols-2 xl:grid-cols-3 place-content-center gap-4 px-5 xl:px-10 my-5 xl:my-10'>
                            <div className='rounded-xl bg-gray-100 border border-white border-4 flex px-5 py-7 mb-4 h-full lg:m-0 shadow' style={{ background: "rgb(244,245,254)",
                                background: "linearGradient(0deg, rgba(244,245,254,1) 0%, rgba(255,255,255,1) 37%)" }}>
                                <picture style={{ width: '10%' }}>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_35/q_auto/e_trim/gatsbycms/uploads/2023/08/perks-dashboard-icon1.webp" type="image/webp" alt="dashboard" width="35px" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_27/q_auto/e_trim/gatsbycms/uploads/2023/08/perks-dashboard-icon1.png" alt="dashboard" width="27px" />
                                </picture>
                                <div style={{ width: '90%' }}><p className='pl-3 p-0 m-0'>Track and measure the impact of your Discounts Program.</p></div>
                            </div>
                            <div className='rounded-xl bg-gray-100 border border-white border-4 flex px-5 py-7 mb-4 h-full lg:m-0 shadow' style={{ background: "rgb(244,245,254)",
                                background: "linearGradient(0deg, rgba(244,245,254,1) 0%, rgba(255,255,255,1) 37%)" }}>
                                <picture style={{ width: '10%' }}>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_35/q_auto/e_trim/gatsbycms/uploads/2023/08/perks-dashboard-icon2.webp" type="image/webp" alt="dashboard" width="35px" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_27/q_auto/e_trim/gatsbycms/uploads/2023/08/perks-dashboard-icon2.png" alt="dashboard" width="27px" />
                                </picture>
                                <div style={{ width: '90%' }}><p className='pl-3 p-0 m-0'>Uncover the ROI of the program with precision.</p></div>
                            </div>
                          
                            <div className='rounded-xl bg-gray-100 border border-white border-4 flex px-5 py-7 mt-4 h-full lg:m-0 shadow' style={{ background: "rgb(244,245,254)",
                                background: "linearGradient(0deg, rgba(244,245,254,1) 0%, rgba(255,255,255,1) 37%)" }}>
                                <picture style={{ width: '10%' }}>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_35/q_auto/gatsbycms/uploads/2023/08/perks-dashboard-icon3.webp" type="image/webp" alt="dashboard" width="35px" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_27/q_auto/gatsbycms/uploads/2023/08/perks-dashboard-icon3.png" alt="dashboard" width="27px" />
                                </picture>
                                <div style={{ width: '90%' }}><p className='pl-3 p-0 m-0'>Use comprehensive analytics to align the program with your needs.</p></div>
                            </div>
                        </div>
                        <div className='pt-6 px-4'>
                         
                            <picture className=''>
                                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1248/q_auto/gatsbycms/uploads/2023/11/Perks-dashboard-2-scaled.webp" media= "(min-width:640px)" type="image/webp" alt="dashboard" width="1248" height="602" />
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_362/q_auto/gatsbycms/uploads/2023/11/Perks-dashboard-2-scaled.webp" alt="dashboard" width="362" height="150" />
                            </picture>
                        </div>
                    </div>
                </div> 
            </section>

            <section className='w-full bg-indigo-100 flex place-content-center py-10 xl:py-20 px-3 xl:px-0 lg:relative'>
                <div className='w-full xl:w-11/12 2xl:max-w-7xl lg:pl-2 xl:pl-3 2xl:pl-0 lg:flex justify-around'>
                    <div className='w-full lg:w-2/3'>
                        <picture>
                            <source className='mx-auto lg:mx-0' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_140/q_auto/gatsbycms/uploads/2023/09/perks-rewardsperks.webp" media= "(min-width:640px)" type="image/webp" alt="dashboard" width="140" height= "80" />
                            <img className='mx-auto lg:mx-0' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_140/q_auto/gatsbycms/uploads/2023/09/perks-rewardsperks.png" alt="dashboard" width="140" height= "80" />
                        </picture>
                        <h2 className='leading-15 xl:leading-12 text-2xl md:text-4xl xl:text-5xl 2xl:text-3rem lato text-center lg:text-left text-purple-100 my-7 lg:pl-3'>Vantage Perks <span className='p-2' style={{ backgroundColor: '#3b3b6b' }}>absolutely free <br/></span> with Vantage Rewards!</h2>
                        <div className='mx-auto lg:mx-0 lg:w-3/6 xl:w-2/6 pt-4 px-2 lg:pl-3'><a className= "vc-new-white-border-btn-rounded-full text-white mx-auto" href = "#PerksVC">Book a product demo</a></div>
                    </div>
                    <div className='w-full flex place-content-center lg:w-5/12 2xl:w-1/3 mt-10 xl:mt-0 z-10'>
                        <picture className='lg:absolute -bottom-24 right-1/6 xl:pl-5'>
                            <source loading="" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_510/q_auto/gatsbycms/uploads/2023/09/perks-rewards.webp" media= "(min-width:640px)" type="image/webp" alt="dashboard" width="510" height="586" />
                            <img loading="" src="https://res.cloudinary.com/vantagecircle/image/upload/w_406/q_auto/gatsbycms/uploads/2023/09/perks-rewards.png" alt="dashboard" width="406" height="403" />
                        </picture>
                    </div>
                </div>
                <div className='w-80 h-80 hidden lg:block absolute -bottom-40 right-1/2 border rounded-full z-0' style= {{ borderWidth: '32px', background:'transparent', borderColor: '#393966', clipPath: 'polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)' }}></div>
            </section>

            <section className='w-full grid place-content-center pt-10 lg:pt-20'>
                <div className='lg:max-w-6xl grid place-content-center px-6'>
                    <h2 className='homepage-heading lato text-center'>Clients that trust us</h2>
                    <picture>
                        <source className='mt-10 xl:mt-16 xl:mb-4 mx-auto' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1232/q_auto/gatsbycms/uploads/2023/10/perks-clients-strip_.webp" media="(min-width: 640px)" type="image/webp" alt="client strip" width= "1232" height= "73" />
                        <img className='mt-10 xl:mt-16 xl:mb-4' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_330/q_auto/gatsbycms/uploads/2023/10/perks-client-strip-mobile.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_330/q_auto/gatsbycms/uploads/2023/10/perks-client-strip-mobile.webp" alt="client-strip" width="400" height= "65" />
                    </picture> 
                    <div className='md:flex pt-12 xl:pt-16 xl:items-center'>
                        <div className='lg:w-2/12'>
                        <picture>
                            <source className='mx-auto pb-0 transform scale-75 xl:scale-90' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2023/09/SG.webp" media="(min-width: 640px)" type="image/webp" alt="client strip" width= "179" height= "179" />
                            <img className='mx-auto pb-0 transform scale-75 xl:scale-90' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_327/q_auto/gatsbycms/uploads/2023/09/SG.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_327/q_auto/gatsbycms/uploads/2023/09/SG.webp" alt="client-strip" width="327" height= "327" />
                        </picture> 
                            <p className='mb-6 block md:hidden text-center'><span className='font-bold'>Sameer Gadgil</span>, Vice President & HR Head, Wipro</p>
                        </div>
                        <div className='lg:w-9/12 xl:w-10/12 lg:ml-8'>
                            <p className='text-gray-200 italic'>
                                "Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees."
                            </p>
                            <p className='mt-3 hidden md:block'><span className='font-bold'>Samir Gadgil</span>, Vice President & HR Head, Wipro</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="w-full flex place-content-center overflow-x-hidden py-10 lg:py-28 xl:pt-28 xl:pb-16 overflow-y-hidden px-6 lg:px-0" id="PerksVC" >
                <div class="lg:flex lg:w-11/12 xl:max-w-7xl mx-auto bg-indigo-100 overflow-hidden rounded-xl px-0 relative">
                    <div class="card-1 w-full p-5 pb-0 md:px-7 md:pt-7 lg:pl-8 xl:p-7 xl:pl-12 md:mb-0 xl:mt-0 xl:w-7/12 overflow-hidden relative md:flex">
                        <div className='md:w-1/2 lg:w-full lg:my-auto'>     
                            <h2 class="text-3xl lg:text-4xl mx-auto md:mx-0 text-center md:text-left text-purple-100 pt-5 xl:pt-0 mb-2 xl:mb-2 lg:px-0 lg:w-10/12 xl:w-11/12 2xl:w-10/12">Implement and manage employee discount programs with <span className='text-orange'>ease</span></h2>
                            <p className='lg:w-11/12 mx-auto lg:mx-0 text-center md:text-left text-purple-100 lg:pl-1 m-0 pb-5 opacity-0'></p>
                            <div className='lg:grid mt-8 md:justify-around lg:justify-start lg:items-center'>
                                <div className='my-2'>
                                    <h2 className='text-3xl lg:text-4xl' style= {{ color: '#fea6d2' }}>9M+</h2>
                                    <p className='text-gray-300'>Points earned as cashback</p>
                                </div>
                                <div className='my-2'>
                                    <h2 className='text-3xl lg:text-4xl' style= {{ color: '#fea6d2' }}>2.3M+</h2>
                                    <p className='text-gray-300'>Exclusive offers availed</p>
                                </div>
                                <div className='my-2'>
                                    <h2 className='text-3xl lg:text-4xl' style= {{ color: '#fea6d2' }}>3M+</h2>
                                    <p className='text-gray-300'>Coupons generated</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex md:w-1/2 lg:w-1/3 place-content-center md:justify-end lg:hidden xl:block xl:absolute right-5 -bottom-12' style={{ zIndex: '5' }}>
                            <picture>
                                <source className="mt-3 transform scale-90 xl:scale-100 origin-bottom" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/03/perks04-1.webp" media="(min-width: 640px)" type="image/webp" alt="Capterra" width="300" height="456" />
                                <img className='mt-3 transform scale-90 xl:scale-100 origin-bottom' src="https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/03/perks04-1.webp" alt="Capterra" width="280" height="441" />
                            </picture>
                        </div>
                    </div>
                    <div className='w-full xl:w-1/2 pt-0 px-4 pb-8 lg:p-8'>
                        <div class="card-1 md:w-5/6 xl:w-full mx-auto rnrForm xl:mt-0 flex flex-col place-items-center px-4 lg:px-6 py-7 lg:py-10 bg-indigo-50 frostedTransparentBG rounded-xl z-10 relative" style={{borderWidth: "0.3px", borderColor: "#ddd" }} id="form">
                            <div class="w-full flex justify-center items-center lato rewards team">
                                <div class="flex justify-center items-center">
                                    <HubspotForm
                                    portalId="6153052"
                                    formId="d2e2c7bf-c255-4b72-a236-53240ebeed17"
                                    onSubmit={() => console.log("Submit!")}
                                    onReady={form => console.log("Form ready!")}
                                    loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </Layout>
  )
}

export default Vcperks