import React from 'react'
import ReactDOM  from 'react-dom'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel'

const PerksDiscountCarousel = () => {
  return (
    <CarouselProvider className="max-w-4xl relative mx-auto xl:pt-10"
    naturalSlideWidth={100}
    naturalSlideHeight={100}
    totalSlides={2}
    visibleSlides= {1}
    infinite={true}
    >
        <ButtonBack className="shadow eb-carousel-btn rounded-full p-4 mx-3 absolute -left-16 top-1/4">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id= "prev" d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
            </ButtonBack>
        <Slider id='discountSprite' className='mr-1'>
            <Slide index={0}>
                <picture className='z-10'>
                    <source className='' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/perks-mobile-strip-icons-1.webp" type="image/webp"alt="Vantage Rewards" width="2000" />
                    <img className='' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/perks-mobile-strip-icons-1.webp" alt="Vantage Rewards" width="2000" />
                </picture>
            </Slide>
            <Slide index={1}>
                <picture className='z-10'>
                    <source className='' srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/perks-mobile-strip-icons-2.webp" type="image/webp"alt="Vantage Rewards" width="2000" />
                    <img className='' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/09/perks-mobile-strip-icons-2.webp" alt="Vantage Rewards" width="2000" />
                </picture>
            </Slide>
            {/* <Slide index={2}>
                <picture className='absolute left-5 -top-4 z-10'>
                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/perks-exclusive-discount.webp" type="image/webp"alt="Vantage Rewards" width="44" />
                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/perks-exclusive-discount.webp" alt="Vantage Rewards" width="44" />
                </picture>
            </Slide> */}
        </Slider>
        <ButtonNext className="shadow eb-carousel-btn rounded-full p-4 mx-3 absolute -right-16 top-1/4">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="next" d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
        </ButtonNext>
        <div className="w-full flex justify-center items-center my-9">
            
          
        </div>
    </CarouselProvider>
  )
}

export default PerksDiscountCarousel